
import web from '@/api/web/co.web'

const Names = {
  WORKS: 'works',
  ALBUMS: 'albums',
  VIDEOS: 'videos',
  CIRCLES: 'circles',
  ARTICLES: 'articles',
  ACTIVITYS: 'activitys'
}

const ArticleNames = {
  DRAFTS: 'drafts',
  ISSUEDS: 'issueds'
}

const ArticleRapids = {
  edit: {
    text: '编辑',
    icon: 'mdi-file-edit-outline',
    value: 'edit',
    disabled: false
  },
  preview: {
    text: '预览',
    icon: 'mdi-file-eye-outline',
    value: 'preview',
    disabled: false
  },
  remove: {
    text: '删除',
    icon: 'mdi-trash-can-outline',
    value: 'remove',
    disabled: false
  },
  publish: {
    text: '开放',
    icon: 'mdi-cloud-arrow-up-outline',
    value: 'publish',
    disabled: false
  },
  down: {
    text: '隐藏',
    icon: 'mdi-cloud-arrow-down-outline',
    value: 'down',
    disabled: false
  }
}

const DraftActions = [
  ArticleRapids.edit,
  ArticleRapids.preview,
  ArticleRapids.remove
]

const IssuedActions = [
  ArticleRapids.edit,
  ArticleRapids.publish,
  ArticleRapids.down,
  ArticleRapids.preview
]

const GrpDrafts = {
  title: '草稿箱',
  name: ArticleNames.DRAFTS,
  type: web.comm.ContentTypes.ARTICLE,
  state: web.comm.States.CREATE,
  pageSize: '12',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 3
  },
  empty: {
    icon: 'mdi-cloud-alert-outline',
    text: '草稿箱无文章',
  },
  actions: DraftActions
}

const IssuedStates = [
  web.comm.States.PUBLISH,
  web.comm.States.DOWN
]

const GrpIssueds = {
  title: '发布历史',
  name: ArticleNames.ISSUEDS,
  type: web.comm.ContentTypes.ARTICLE,
  state: IssuedStates.join('|'),
  pageSize: '12',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 3
  },
  empty: {
    icon: 'mdi-cloud-alert-outline',
    text: '你还没发布过文章',
  },
  actions: IssuedActions
}

const CatWorks = {
  title: '图片',
  label: '图片',
  name: Names.WORKS,
  type: web.comm.ContentTypes.IMAGE,
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatAlbums = {
  title: '影集',
  label: '影集',
  name: Names.ALBUMS,
  type: '',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: false,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatVideos = {
  title: '视频',
  label: '视频',
  name: Names.VIDEOS,
  type: web.comm.ContentTypes.VIDEO,
  ratio: 1.64,
  margin: 'ma-4',
  tooled: true,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatCircles = {
  title: '圈子',
  label: '圈子',
  name: Names.CIRCLES,
  type: '',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatArticles = {
  title: '文章',
  label: '文章',
  name: Names.ARTICLES,
  type: web.comm.ContentTypes.ARTICLE,
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatActivitys = {
  title: '活动',
  label: '活动',
  name: Names.ACTIVITYS,
  type: '',
  ratio: 1.45,
  margin: 'ma-4',
  tooled: true,
  navigated: false,
  params: null,
  items: [],
  total: 0,
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const myPreset = {
  Names,
  ArticleNames,
  Parts: {
    [Names.WORKS]: CatWorks,
    [Names.ALBUMS]: CatAlbums,
    [Names.VIDEOS]: CatVideos,
    [Names.CIRCLES]: CatCircles,
    [Names.ARTICLES]: CatArticles,
    [Names.ACTIVITYS]: CatActivitys
  },
  ArticleGroups: {
    [ArticleNames.DRAFTS]: GrpDrafts,
    [ArticleNames.ISSUEDS]: GrpIssueds
  },
  DraftActions,
  IssuedActions,
  ArticleRapids
}

export default myPreset
