
import user from '@/api/co.user'
// import web from '@/api/web/co.web'
import mixPreset from '@/pages/mixins/my/mix.my.preset'

const parts = mixPreset.Parts
const names = mixPreset.Names

export default {
  data () {
    return {
      currTab: names.WORKS,
      tabList: [
        parts[names.WORKS],
        parts[names.ALBUMS],
        parts[names.VIDEOS],
        parts[names.ARTICLES]
      ],
      tipHot: {
        label: '热度',
        count: 0
      },
      tipAttention: {
        label: '关注',
        count: 0
      },
      tipFans: {
        label: '粉丝',
        count: 0
      },
    }
  },
  created () {
  },
  methods: {
    getPart (name) {
      let result = null
      for (const i in this.tabList) {
        const item = this.tabList[i]
        if (item.name === name) {
          result = item
          break
        }
      }
      return result
    },
    configExtend (userId) {
      const me = this
      const executed = function (res) {
        // console.log({ res })
        if (res.status) {
          const data = res.data || {}
          me.tipAttention.count = data.toFansNum || 0
          me.tipFans.count = data.fansNum || 0
          me.tipHot.count = data.hotNum || 0
        }
      }

      user.info.extendBy({
        userId,
        executed
      })
    },
  }
}
