import api from '@/api/co.api'
import app from '@/api/co.app'
import user from '@/api/co.user'
import web from '@/api/web/co.web'

export default {
  mixins: [],
  data () {
    return {
      userId: '',
      info: {},
      imTo: {},
      btnChat: {
        text: '私信'
      },
      presBanners: [],
      userBanners: [],
      classify: {
        height: 720,
        points: {
          xs: 200,
          sm: 320,
          md: 410,
          lg: 720,
          xl: 800
        },
        cycle: true,
        interval: 5000,
        hideDelimiters: false,
        showArrows: true
      },
    }
  },
  computed: {
    wdBanners () {
      let list = this.userBanners
      if (list.length < 1) {
        list = this.presBanners
      }
      return list
    },
    wdHideDelimiters () {
      let list = this.userBanners
      if (list.length < 1) {
        list = this.presBanners
      }
      if (list.length > 1) {
        return this.classify.hideDelimiters
      } else {
        return true
      }
    },
    wdShowArrows () {
      let list = this.userBanners
      if (list.length < 1) {
        list = this.presBanners
      }
      if (list.length > 1) {
        return this.classify.showArrows
      } else {
        return false
      }
    }
  },
  created () {
  },
  methods: {
    toLogin () {
      const params = {
        mode: 'phone'
      }
      api.page.navigate(this, 'login', params)
    },
    toChat () {
      const dlg = this.$refs.dlgChat
      if (dlg) {
        dlg.show(this.imTo)
      }
    },
    onChat () {
      const me = this
      const callback = function (res) {
        if (res.status) {
          me.toChat()
        } else {
          me.$confirm('登录后才能私信, 是否现在登录?', '登录提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'info'
          }).then(() => {
            me.toLogin()
          }).catch(() => {
            me.$message({
              type: 'info',
              message: '已取消登录！'
            })
          })
        }
      }

      user.login.isLogined(callback)
    },
    configUserInfo (userId) {
      this.userId = userId
      this.getUserInfo(userId)
      this.getDefaultBanner()
    },
    getDefaultBanner () {
      const me = this
      let params = null
      const executed = function (res) {
        for (const i in params.items) {
          const item = params.items[i]
          me.presBanners.push({
            cover: item.cover
          })
        }

        if (me.presBanners.length < 1) {
          me.presBanners.push({
            cover: app.pic.cover_my
          })
        }
      }

      params = web.banner.getParams({
        type: web.comm.BannerTypes.MY_MAJOR,
        state: web.comm.States.PUBLISH,
        executed
      })

      api.httpx.getItems(params)
    },
    getUserInfo (userId) {
      const me = this
      me.userBanners = []
      const executed = function (res) {
        if (res.status) {
          me.info = res.data
          me.imTo = {
            userId: me.info.userId,
            username: me.info.username,
            mobile: me.info.mobile,
            avatar: me.info.avatar
          }
          if (me.info.backgroundImage) {
            me.userBanners.push({
              cover: me.info.backgroundImage
            })
          }
        }
      }

      user.info.pull({
        id: userId,
        executed
      })
    },
  }
}
