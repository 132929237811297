<template>
  <section>
    <land-banner
      :items="wdBanners"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />
    <land-section
      id="user_center_info"
      space="4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <div class="pa-5" />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div
              class="co-flex-col co-justify-center co-items-center co-w-full px-4 pt-4"
            >
              <v-badge
                avatar
                overlap
                bottom
                :value="info.badge !== ''"
              >
                <template #badge>
                  <v-avatar>
                    <v-img
                      :src="info.badge"
                    />
                  </v-avatar>
                </template>
                <v-avatar
                  size="96"
                >
                  <v-img
                    :src="info.avatar"
                  />
                </v-avatar>
              </v-badge>

              <div class="co-flex-row co-items-center">
                <span class="text-h5 my-2">
                  {{ info.username }}
                </span>
              </div>

              <span
                class="text-body-1"
                :class="[info.identity ? 'primary--text' : 'blue-grey--text lighten-3']"
              >
                {{ info.identity || '未认证' }}
              </span>
              <span class="text-caption my-3">
                {{ `IP属地：${info.ipBelong || '未知'}` }}
              </span>
              <div class="co-flex-row">
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipHot.label }}
                  <span class="pl-1">{{ tipHot.count }}</span>
                </span>
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipFans.label }}
                  <span class="pl-1">{{ tipFans.count }}</span>
                </span>
                <span class="text-body-1 blue-grey--text px-3">
                  {{ tipAttention.label }}
                  <span class="pl-1">{{ tipAttention.count }}</span>
                </span>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <div class="co-flex-row co-justify-end mt-8">
              <v-btn
                color="primary"
                @click="onChat"
              >
                {{ btnChat.text }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <v-divider />
    <land-section
      id="my_center_tabs"
      space="4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-card
          color="transparent"
          flat
          tile
        >
          <v-tabs
            v-model="currTab"
            background-color="transparent"
            class="d-flex flex-row justify-center align-center"
          >
            <v-tab
              v-for="(tab, tabIndex) in tabList"
              :key="tabIndex"
              :value="tab.name"
              :href="`#${tab.name}`"
              class="text-h6"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-container>
    </land-section>
    <land-section
      id="my_center_items"
      space="20"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 1800px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-tabs-items v-model="currTab">
              <v-tab-item
                value="works"
                class="grey lighten-4"
              >
                <user-works
                  ref="user_works"
                  :user-id="userId"
                />
              </v-tab-item>
              <v-tab-item
                value="albums"
                class="grey lighten-4"
              >
                <user-albums
                  ref="user_albums"
                  :user-id="userId"
                />
              </v-tab-item>
              <v-tab-item
                value="videos"
                class="grey lighten-4"
              >
                <user-videos
                  ref="user_videos"
                  :user-id="userId"
                />
              </v-tab-item>
              <v-tab-item
                value="articles"
                class="grey lighten-4"
              >
                <user-articles
                  ref="user_articles"
                  :user-id="userId"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <dialog-chat ref="dlgChat" />
  </section>
</template>

<script>
  import app from '@/api/co.app'

  import mixUserBoot from '@/pages/mixins/user/mix.user.boot'
  import mixUserInfo from '@/pages/mixins/user/mix.user.info'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    components: {
      UserWorks: () => import('@/pages/sections/user/UserWorks.vue'),
      UserAlbums: () => import('@/pages/sections/user/UserAlbums.vue'),
      UserVideos: () => import('@/pages/sections/user/UserVideos.vue'),
      UserArticles: () => import('@/pages/sections/user/UserArticles.vue'),
      DialogChat: () => import('@/pages/sections/comm/DialogChat.vue')
    },
    mixins: [
      mixUserBoot,
      mixUserInfo
    ],
    data () {
      return {
        drawer: false,
        pageTitle: '个人中心',
      }
    },
    created () {
      const userId = this.$route.query.userId

      app.on(app.event.USER_WORKS_GET, this.onUserContentsGet)
      app.on(app.event.USER_ALBUMS_GET, this.onUserContentsGet)
      app.on(app.event.USER_VIDEOS_GET, this.onUserContentsGet)
      app.on(app.event.USER_ARTICLES_GET, this.onUserContentsGet)
      if (userId) {
        this.configUserInfo(userId)
        this.configExtend(userId)
      }
    },
    destroyed () {
      app.off(app.event.USER_WORKS_GET)
      app.off(app.event.USER_ALBUMS_GET)
      app.off(app.event.USER_VIDEOS_GET)
      app.off(app.event.USER_ARTICLES_GET)
    },
    methods: {
      onUserContentsGet (ev) {
        const part = this.getPart(ev.name)
        if (part) {
          const title = ev.total ? ev.title : ev.label
          this.$set(part, 'title', title)
        }
      },
    }
  }
</script>
